// import 'vite/modulepreload-polyfill';

import '../css/main.css';

//
import lazyLoadModule from './utils/loaders/moduleLoader.js';

lazyLoadModule('gtm', 3000, { gtmID: window.gtmID }).then(() => {});

lazyLoadModule('alpineComponents', 0).then(() => {
  console.log('alpineComponents has been loaded');
});
console.log('HRM TEst');

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  // HMR code
  import.meta.hot.accept();
}
